import type { ReactNode } from 'react';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/redux';
import Wallet from 'modules/Wallet';
import { formatCurrency } from 'lib/formatters';
import { promotionCode as promotionCodeValidation } from 'lib/valFuncs';
import { depositAmount as depositAmountValidation } from 'lib/valFunctors';
import { isLoading } from 'lib/redux-utils';
import { useSecureFieldsManager } from 'components/PciProxy';
import Api from 'services/Api';
import Auth from 'modules/Auth';
import '../depositCardForm.css';
import DepositPciProxyCardForm from './DepositPciProxyCardForm';

interface DepositPciProxyCardFormAdapterProps {
  promoCode: string;
  provider: string;
  disabled: boolean;
  paymentMethodRef: string;
  agreeDepositTerms: boolean;
  fundsProtection?: ReactNode;
  disableDepositButton?: boolean;
  isPromoCodeDisabled: boolean;
  isDepositWelcomeOfferVisible: boolean;
  onDone: (amount: number | null, skipModalClosing: boolean) => void;
}

const DepositPciProxyCardFormAdapter = ({
  promoCode,
  provider,
  disabled,
  paymentMethodRef,
  fundsProtection,
  disableDepositButton,
  agreeDepositTerms,
  isPromoCodeDisabled,
  isDepositWelcomeOfferVisible,
  onDone
}: DepositPciProxyCardFormAdapterProps) => {
  const radioValues = useAppSelector(
    (state) => Wallet.selectors.getPaymentPresetPresets(state, provider) as number[] | undefined
  );
  const minAmount = useAppSelector(
    (state) => Wallet.selectors.getPaymentPresetMinAmount(state, provider) as number | undefined
  );
  const maxAmount = useAppSelector(
    (state) => Wallet.selectors.getPaymentPresetMaxAmount(state, provider) as number | undefined
  );
  const pciProxyError = useAppSelector(
    (state) =>
      Wallet.selectors.getPciProxyError(state) as { hasError: boolean; message: string } | undefined
  );
  const isLoadingData = useAppSelector((state) =>
    isLoading(state, [
      Wallet.actionTypes.AT.DEPOSIT_CARD_PCI_PROXY._,
      Wallet.actionTypes.AT.PCI_PROXY_3D_CHALLENGE._,
      Auth.AT.FINGERPRINT._
    ])
  );
  const dispatch = useDispatch();
  const defaultAmount = radioValues?.[0];
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const amount = useRef<number | null>(null);
  const code = useRef<string | null>(null);

  const fields = useMemo(
    () => ({
      depositAmount: {
        initial: defaultAmount || 50,
        required: true,
        error: `Minimum amount ${formatCurrency(10)}`,
        onChange: depositAmountValidation(minAmount, maxAmount)
      },
      promotionCode: {
        initial: promoCode || '',
        required: false,
        onChange: promotionCodeValidation,
        error: "This doesn't look like a valid code"
      }
    }),
    [defaultAmount, maxAmount, minAmount, promoCode]
  );

  const onSuccess = useCallback(
    async (transactionId: string) => {
      setErrorMessage(null);
      const fingerprintResult = dispatch(
        Auth.actions.getDeviceFingerprint()
      ) as unknown as Promise<{ value: any }>;
      const { value: deviceInfo } = await fingerprintResult;

      // save transacitonid in store
      dispatch(Wallet.actions.setCurrentPci3dChallengeTransactionId(transactionId));

      const pciProxy = {
        cvvTransactionId: transactionId,
        // FIXME: MQPV-465 Do not use PUBLIC_URL for this, `window.location.hostname` is correct
        successUrl: `${__ENV__.PUBLIC_URL}/assets/pages/challenge-completed.html`,
        cancelUrl: `${__ENV__.PUBLIC_URL}/assets/pages/challenge-canceled.html`,
        errorUrl: `${__ENV__.PUBLIC_URL}/assets/pages/challenge-rejected.html`
      };

      try {
        await Api.actions.wallet.depositCardPciProxy(null, {
          paymentMethodRef,
          amount: amount.current,
          promotionCode: code.current,
          deviceInfo,
          agreeDepositTerms,
          pciProxy
        })(dispatch, true);
        // The amount is not really used in the handleDeposit
        // even though most of the deposit forms are passing it to the onDone callback
        // MQPV-437 - Get rid of the amount parameter
        onDone(amount.current, true);
      } catch (e: any) {
        setErrorMessage(e.msg);
      }
    },
    [agreeDepositTerms, dispatch, onDone, paymentMethodRef]
  );

  const onError = useCallback((message: string) => {
    setErrorMessage(message);
  }, []);

  const { secureFields, cvv, isValid, onReset } = useSecureFieldsManager({
    field: 'cvv',
    onSuccess,
    onError
  });

  useEffect(() => {
    if (pciProxyError?.hasError) {
      dispatch(Wallet.actions.setPciProxyError(false, null));
      setErrorMessage(pciProxyError.message);
      onReset();
    }
  }, [dispatch, onReset, pciProxyError]);

  const onSubmit = useCallback(
    ({ depositAmount, promotionCode }: { depositAmount: number; promotionCode: string }) => {
      amount.current = depositAmount;
      code.current = promotionCode;
      secureFields?.submit();
    },
    [secureFields]
  );

  const onFocus = useCallback(() => {
    secureFields?.focus('cvv');
  }, [secureFields]);

  return (
    <DepositPciProxyCardForm
      fields={fields}
      submit={onSubmit}
      radioValues={radioValues}
      defaultAmount={defaultAmount}
      minAmount={minAmount}
      maxAmount={maxAmount}
      cvv={cvv}
      disabled={disabled}
      isPciProxyValid={isValid}
      handlePciFocus={onFocus}
      pciProxyErrorMessage={errorMessage}
      loading={isLoadingData}
      fundsProtection={fundsProtection}
      isPromoCodeDisabled={isPromoCodeDisabled}
      isDepositWelcomeOfferVisible={isDepositWelcomeOfferVisible}
      disableDepositButton={disableDepositButton}
    />
  );
};

export default memo(DepositPciProxyCardFormAdapter);
