import Api from 'services/Api';
import type { AppDispatch } from '../../../../store';

type RegistrationOfferArgs = {
  affiliateId?: string;
  promoCode?: string;
};

export const registrationOffer = (args: RegistrationOfferArgs) => (dispatch: AppDispatch) =>
  Api.actions.offers.registration(args)(dispatch);

export const referAFriendOffer = () => (dispatch: AppDispatch) =>
  Api.actions.offers.referAFriend()(dispatch);

export const rewardsCarousel = () => (dispatch: AppDispatch) =>
  Api.actions.offers.rewardsCarousel()(dispatch);
