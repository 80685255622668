import React, { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import cc from 'classcat';
import loadable from '@loadable/component';
import { withSentryRouting } from '@sentry/react';
import getSignupRoute from 'lib/getSignupRoute';
import FullscreenLoading from 'components/Loading/Fullscreen';
import ModalOverlay from 'components/Modals/ModalOverlay';
import MaintenanceNotice from 'components/MaintenanceNotice';
import AppWrapperDataBridge from 'components/AppWrapperDataBridge';
import { currencyFormatter } from 'lib/formatters';

// Parameterized transaction names
const SentryRoute = withSentryRouting(Route);

const PublicModals = loadable(() =>
  import(
    /* webpackPrefetch: true, webpackChunkName: "publicModals" */
    'components/Modals/PublicModals'
  )
);

const Login = loadable(
  () => import(/* webpackPreload: true, webpackChunkName: "login" */ 'components/Login'),
  {
    fallback: <FullscreenLoading />
  }
);
const Signup = loadable(
  () => import(/* webpackPrefetch: true, webpackChunkName: "signup" */ 'components/Signup'),
  {
    fallback: <FullscreenLoading />
  }
);
const MainPublicNavbar = loadable(() =>
  import(/* webpackPreload: true, webpackChunkName: "publicNavbar" */ 'components/PublicNavbar')
);
const SignupPublicNavbar = loadable(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "signupPublicNavbar" */
    'components/SignupPublicNavbar'
  )
);
const ResetPassword = loadable(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "reset-password" */ 'components/ResetPassword'
    ),
  {
    fallback: <FullscreenLoading />
  }
);

const signupRoute = getSignupRoute();
const PublicRoutes = ({ search }) => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <SentryRoute exact path="/signup/:step" component={Signup} />
    <Route exact path="/reset-password" component={ResetPassword} />
    <Redirect exact from="/signup(\?.*)?" to={`/signup/${signupRoute}${search}`} />
    <Redirect exact from="*" to={`/login${search}`} />
  </Switch>
);

const Public = ({ location: { pathname, search } }) => {
  let PublicNavbar = null;
  if (!pathname.startsWith('/signup')) {
    PublicNavbar = MainPublicNavbar;
  } else {
    PublicNavbar = SignupPublicNavbar;
  }

  return (
    <div
      className={cc([
        'app-root',
        {
          'app-root--signup': pathname.startsWith('/signup')
        }
      ])}
      style={{ '--currencySymbol': JSON.stringify(currencyFormatter.symbol) }}
    >
      <MaintenanceNotice pathname={pathname} />
      <ModalOverlay />
      {PublicNavbar ? <PublicNavbar search={search} pathname={pathname} /> : null}
      <div className="contentContainer contentContainer--public">
        <PublicModals />
        <PublicRoutes search={search} pathname={pathname} />
      </div>
      <AppWrapperDataBridge />
      <div id="tooltipPortal" />
    </div>
  );
};

export default memo(Public);
