import React from 'react';
import { mod } from 'react-swipeable-views-core';
import type { SpinDay as SpinDayType } from 'modules/Offers/src/types';
import SpinDay from '../../../SpinDay';

const spinSlideRenderer = (spins: SpinDayType[]) => {
  const itemsTotal = spins.length;

  const slideRenderer = (params: { index: number; key: number }) => {
    const { index, key } = params;
    const { ref, thumbnailUrl, isBlocked, status, dateText, details, expiresText } =
      spins[mod(index, itemsTotal)] ?? {};

    if (!ref) {
      return null;
    }

    return (
      <SpinDay
        key={`${ref}${key}`}
        refName={ref}
        thumbnailUrl={thumbnailUrl}
        isBlocked={isBlocked}
        status={status}
        dateText={dateText}
        expiresText={expiresText}
        details={details}
      />
    );
  };

  return slideRenderer;
};

export default spinSlideRenderer;
