/* eslint-disable @typescript-eslint/no-unsafe-return -- No full TS support in redux */
import React, { memo } from 'react';
import cc from 'classcat';
import { DailyFreeGamesList } from 'components/DailyFreeGamesList';
import SlotRewardsList from 'components/SlotRewardsList';
import User from 'modules/User';
import Offers from 'modules/Offers';
import { useAppSelector } from 'hooks/redux';
import { Typography, TypographyVariant } from 'components/Typography';
import NoRewards from 'components/RewardCard/NoRewards';
import SpinRewards from 'components/SpinRewards';
import RewardsListLoading from './RewardsListLoading';
import './rewardsList.css';

const RewardsList = () => {
  const loadingAllRewards = useAppSelector((state) => User.selectors.getAllRewardsLoading(state));
  const hasLoadedAllRewards = useAppSelector((state) =>
    User.selectors.getHasLoadedAllRewards(state)
  );
  const loadingRewardsCarousel = useAppSelector(Offers.selectors.getCarouselRewardsLoading);

  const hasRewards = useAppSelector((state) => User.selectors.getRewardsExist(state));
  const carouselRewards = useAppSelector(Offers.selectors.getCarouselRewards);
  const hasCarouselRewards = Boolean(carouselRewards?.length);
  const isLoading = (loadingAllRewards && !hasLoadedAllRewards) || loadingRewardsCarousel;
  const hasData = hasRewards || hasCarouselRewards;

  return (
    <div className="rewardsList hide-scrollbar">
      {hasCarouselRewards && <SpinRewards />}
      <div
        className={cc([
          'rewardsList__content',
          { 'rewardsList__content--withCarousel': hasCarouselRewards }
        ])}
      >
        {isLoading && !hasData ? (
          <RewardsListLoading count={1} />
        ) : (
          hasRewards && (
            <>
              <Typography variant={TypographyVariant.BodySmStrong}>
                <div className="rewardsList__title">Other goodies</div>
              </Typography>
              <SlotRewardsList />
              <DailyFreeGamesList />
            </>
          )
        )}
        {!isLoading && !hasData && <NoRewards />}
      </div>
    </div>
  );
};

export default memo(RewardsList);
