import { useEffect, useState } from 'react';
import Offers from 'modules/Offers';
import { useAppDispatch } from 'hooks/redux';

const MAX_ATTEMPTS = 2;

interface useSpinRewardsRetryProps {
  shouldRetry: boolean;
}

const useSpinRewardsRetry = ({ shouldRetry }: useSpinRewardsRetryProps) => {
  const dispatch = useAppDispatch();
  const [attemptCount, setAttemptCount] = useState(0);

  useEffect(() => {
    if (!shouldRetry || attemptCount >= MAX_ATTEMPTS) {
      return;
    }

    const timeoutDuration = attemptCount === 0 ? 3000 : 10000;
    const timer = setTimeout(() => {
      setAttemptCount((prev) => prev + 1);
      void Offers.actions.rewardsCarousel()(dispatch);
    }, timeoutDuration);

    return () => clearTimeout(timer);
  }, [dispatch, shouldRetry, attemptCount]);
};

export default useSpinRewardsRetry;
