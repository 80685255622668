export const experiments = {
  signupPlus: {
    flag: 'signup-plus'
  },
  rafRedesign: {
    flag: 'raf-redesign'
  },
  depositWelcomeOffer: {
    flag: 'deposit-welcome-offer'
  },
  redirectReturningPlayers: {
    flag: 'redirect-returning-players',
    variants: {
      CONTROL: 'control',
      TREATMENT: 'redirect'
    }
  }
};
