import { isLoading } from 'lib/redux-utils';
import type { RootState } from '../../../../store';
import { NAME } from './constants';
import * as m from './model';
import type { OffersState } from './types';
import { AT } from './actionTypes';

type Selector<A extends undefined | keyof OffersState = undefined> = (
  state: RootState
) => A extends keyof OffersState ? OffersState[A] : OffersState;

export const model: Selector = (state) => (state[NAME] || m.initialState) as OffersState;
export const getOffersByName = (state: RootState, section: 'registration' | 'referAFriend') =>
  model(state)[section];
export const getCarouselRewards = (state: RootState) => model(state).rewardsCarousel;
export const getCarouselRewardsLoading = (state: RootState) =>
  isLoading(state, AT.REWARDS_CAROUSEL._);
