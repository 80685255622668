import * as actions from './src/actions';
import { NAME } from './src/constants';
import { AT } from './src/actionTypes';
import * as selectors from './src/selectors';
import * as model from './src/model';
import reducer from './src/reducer';

export default { actions, NAME, reducer, selectors, model, AT };
export * as actionTypes from './src/actionTypes';
export * from './src/types';
