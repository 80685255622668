import React from 'react';
import cc from 'classcat';
import Button from 'components/Button';
import './arrowButton.css';

const arrowButtonMap = {
  left: {
    name: 'scrollLeft',
    title: 'Scroll left',
    className: 'arrowButton--left'
  },
  right: {
    name: 'scrollRight',
    title: 'Scroll right',
    className: 'arrowButton--right'
  },
  isRowPlaced: 'arrowButton--row-placed'
};

interface ArrowButtonProps {
  direction: 'left' | 'right';
  isRowPlaced?: boolean;
  className?: string;
  handleClick: () => void;
}

const ArrowButton = ({
  direction,
  isRowPlaced = true,
  className,
  handleClick
}: ArrowButtonProps) => (
  <Button
    unstyled
    tag="div"
    name={arrowButtonMap[direction].name}
    title={arrowButtonMap[direction].title}
    className={cc([
      'arrowButton',
      className,
      arrowButtonMap[direction].className,
      isRowPlaced && arrowButtonMap.isRowPlaced
    ])}
    onClick={handleClick}
  />
);

export default ArrowButton;
