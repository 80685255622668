import React from 'react';
import * as Animation from 'components/Animation';
import './offerPromoBannerSkeleton.css';

export const OfferPromoBannerSkeleton = () => (
  /* TODO: uses offerPromoBanner style for container
     remove when we have a Card/Container component */
  <section className="offerPromoBanner offerPromoBannerSkeleton">
    <div>
      <Animation.Root className="offerPromoBannerSkeleton__tile">
        <Animation.ShimmerInputPrimary />
      </Animation.Root>
    </div>
    <div className="offerPromoBannerSkeleton__content">
      <Animation.Root className="offerPromoBannerSkeleton__title">
        <Animation.ShimmerInputPrimary />
      </Animation.Root>
      <Animation.Root className="offerPromoBannerSkeleton__textLine">
        <Animation.ShimmerInputPrimary />
      </Animation.Root>
      <Animation.Root className="offerPromoBannerSkeleton__textLine">
        <Animation.ShimmerInputPrimary />
      </Animation.Root>
    </div>
  </section>
);

export default OfferPromoBannerSkeleton;
