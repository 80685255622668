import React, { memo, useCallback, useMemo } from 'react';
import { mod } from 'react-swipeable-views-core';
import amplitude from 'lib/analytics';
import type { RewardsCarousel } from 'modules/Offers';
import withLazyStyle from 'components/LazyStyle';
import SwipeableCarousel from 'components/SwipeableCarousel';
import spinSlideRenderer from './helpers/spinSlideRenderer';
import style from './spinRewardsCarousel.css?lazy';

const rootStyle = { paddingLeft: 'calc(50% - 55px)', paddingRight: 'calc(50% - 55px)' };
const slideStyle = {
  padding: '0 4px',
  width: '110px'
};
const springConfig = {
  duration: '0.1s',
  easeFunction: 'ease-out',
  delay: '0s'
};

interface SpinRewardsCarouselProps {
  currentSlideIndex: number;
  sectionRef: string;
  sectionTitle: string;
  subOffers: RewardsCarousel['subOffers'];
}

const SpinRewardsCarousel = ({
  currentSlideIndex,
  subOffers,
  sectionRef,
  sectionTitle
}: SpinRewardsCarouselProps) => {
  const slideRenderer = useMemo(() => spinSlideRenderer(subOffers), [subOffers]);

  // Note: This is a temporary solution for event tracking and the eventual use of this data is still being considered.
  const trackSlideChanged = useCallback(
    (newIndex: number, prevIndex: number) => {
      const totalElements = subOffers.length;
      const modPrevIndex = mod(prevIndex, totalElements);
      const modNewIndex = mod(newIndex, totalElements);
      const prevItem = subOffers[modPrevIndex];
      const currentItem = subOffers[modNewIndex];

      amplitude.track('Mayhem Carousel Slide Changed', {
        'Section ID': sectionRef,
        'Section Name': sectionTitle,
        'Previous Slide Position': modPrevIndex + 1,
        'Current Slide Position': modNewIndex + 1,
        'Previous Card ID': prevItem.ref,
        'Current Card ID': currentItem.ref,
        'Previous Card Blocked': prevItem.status !== 'CURRENT' || prevItem.isBlocked,
        'Current Card Blocked': currentItem.status !== 'CURRENT' || currentItem.isBlocked,
        'Total Elements': totalElements
      });
    },
    [subOffers, sectionRef, sectionTitle]
  );

  return (
    <SwipeableCarousel
      className="spinRewardsCarousel"
      index={currentSlideIndex}
      slideRenderer={slideRenderer}
      style={rootStyle}
      onChangeIndex={trackSlideChanged}
      slideStyle={slideStyle}
      hysteresis={0.2}
      overscanSlideAfter={5}
      overscanSlideBefore={5}
      springConfig={springConfig}
    />
  );
};

export default memo(withLazyStyle(style)(SpinRewardsCarousel));
