import React, { useCallback } from 'react';
import cc from 'classcat';
import { type SpinDay as SpinDayType, SpinDayStatus } from 'modules/Offers';
import { Typography, TypographyVariant } from 'components/Typography';
import RouterLink from 'components/RouterLink';
import { useAppDispatch } from 'hooks/redux';
import Analytics from 'modules/Analytics';
import { EffectTypes } from 'services/Effects';
import './spinDay.css';

type SpinDayProps = Omit<SpinDayType, 'ref'> & { refName: string };

const SpinDay = ({
  refName,
  status,
  dateText,
  isBlocked,
  thumbnailUrl,
  expiresText,
  details
}: SpinDayProps) => {
  const dispatch = useAppDispatch();
  const { game, numberOfFreespins } = details ?? {};
  const isReadyToCollect = status === SpinDayStatus.CURRENT && !isBlocked;
  const link = isReadyToCollect ? `/secure/games/${game}` : '';

  const trackCardClicked = useCallback(() => {
    dispatch(
      Analytics.actions.offerClicked({
        refName,
        type: 'Mayhem',
        source: 'Mayhem',
        isBlocked: status !== 'CURRENT' || isBlocked,
        interactionType: EffectTypes.OfferClickedInteractionType.CardClick
      })
    );
  }, [refName, isBlocked, status, dispatch]);

  return (
    <RouterLink
      href={link}
      onClick={trackCardClicked}
      className={cc(['spinDay', { 'spinDay--collectable': isReadyToCollect }])}
    >
      <div className="spinDay__content" style={{ backgroundImage: `url(${thumbnailUrl})` }}>
        <div className="spinDay__textWrapper spinDay__date">
          <Typography variant={TypographyVariant.BodyXxsStrong}>
            <div>{dateText}</div>
          </Typography>
        </div>

        {status !== SpinDayStatus.FUTURE && (
          <div className="spinDay__quantity">
            <Typography variant={TypographyVariant.HeaderXl}>
              <div>{numberOfFreespins}</div>
            </Typography>
            <Typography variant={TypographyVariant.BodyMdStrong}>
              <div className="spinDay__quantitySpins">Spins</div>
            </Typography>
          </div>
        )}

        {status === SpinDayStatus.CURRENT && expiresText && (
          <div className="spinDay__textWrapper spinDay__expiring">
            <Typography variant={TypographyVariant.BodyXxsStrong}>
              <div>{expiresText}</div>
            </Typography>
          </div>
        )}
      </div>
    </RouterLink>
  );
};

export default SpinDay;
