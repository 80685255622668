import React, { memo } from 'react';
import type { Offer } from 'lib/LobbyEntities';
import withLazyStyle from 'components/LazyStyle';
import Tile from 'components/Tile';
import { Typography, TypographyVariant } from 'components/Typography';
import OfferPromoBannerSkeleton from './components/OfferPromoBannerSkeleton';
import style from './offerPromoBanner.css?lazy';

const TILE_WIDTH = 60;

interface OfferPromoBannerProps {
  offer?: Offer;
  isLoading?: boolean;
}

const OfferPromoBanner = ({ offer, isLoading }: OfferPromoBannerProps) => {
  // TODO: Remove heading prop when BE is consistent
  const { title, subtitle, heading, shortDescription, smallImageUrl = '' } = offer || {};

  return isLoading ? (
    <OfferPromoBannerSkeleton />
  ) : (
    <section className="offerPromoBanner">
      <div className="offerPromoBanner__tile">
        <Tile imageUrl={smallImageUrl} resizeWidth={TILE_WIDTH} imageAlt={title} />
      </div>
      <div className="offerPromoBanner__content">
        <Typography variant={TypographyVariant.HeaderXxs}>
          <span className="offerPromoBanner__title">{title}</span>
        </Typography>
        <Typography variant={TypographyVariant.BodyXs}>
          <span className="offerPromoBanner__subtitle">{subtitle || heading}</span>
        </Typography>
        <Typography variant={TypographyVariant.BodyXs}>
          <span className="offerPromoBanner__shortDescription">{shortDescription}</span>
        </Typography>
      </div>
    </section>
  );
};

export default memo(withLazyStyle(style)(OfferPromoBanner));
