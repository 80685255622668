import * as RouterModule from 'modules/Router';
import AnalyticsModule from 'modules/Analytics';
import WalletModule from 'modules/Wallet';

const pageView = (action) => ({
  event: 'pageview',
  hitType: 'pageview',
  page: action.payload.location.pathname
});

const depositSuccess = ({ payload }) => ({
  event: 'depositSuccess',
  depositAmount: parseFloat(payload.amount),
  depositCurrentCount: payload.currentCount
});

const registrationSuccess = ({ payload }) => ({
  event: 'registrationSuccess',
  affiliate: payload
});

const loginSuccess = ({ payload }) => ({
  event: 'loginSuccess',
  firstName: payload.firstName,
  username: payload.username,
  userRef: payload.userRef,
  affiliate: payload.affiliate,
  authenticated: payload.authenticated
});

const profileSuccess = ({ payload }) => ({
  event: 'profileSuccess',
  firstName: payload.firstName,
  username: payload.username,
  userRef: payload.userRef,
  affiliate: payload.affiliate,
  authenticated: payload.authenticated
});

const trackLogout = ({ payload }) => ({
  event: 'userLogout',
  firstName: payload.firstName,
  username: payload.username,
  userRef: payload.userRef,
  affiliate: payload.affiliate,
  authenticated: payload.authenticated
});

const reportError = ({ payload: { providerRef, errorMessage, errorCode } }) => ({
  event: 'reportError',
  providerRef,
  errorMessage,
  errorCode
});

const trackEvent = ({ payload }) => ({
  event: payload
});

const eventsMap = {
  [RouterModule.T.LOCATION_CHANGE]: pageView,
  [WalletModule.actionTypes.AT.DEPOSIT_CARD_BRAINTREE.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_CARD_PCI_PROXY.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_MOBILE.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_PAYPAL.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_ECOSPEND_FIRST.FULFILLED]: depositSuccess,
  [WalletModule.actionTypes.AT.DEPOSIT_ECOSPEND.FULFILLED]: depositSuccess,
  [AnalyticsModule.T.REGISTRATION]: registrationSuccess,
  [AnalyticsModule.T.REPORT_ERROR]: reportError,
  [AnalyticsModule.T.PROFILE]: profileSuccess,
  [AnalyticsModule.T.LOGIN]: loginSuccess,
  [AnalyticsModule.T.LOGGED_OUT]: trackLogout,
  [AnalyticsModule.T.TRACK_EVENT]: trackEvent
};

export default eventsMap;
