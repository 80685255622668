import { handleActions } from 'redux-actions';
import { isNil, isArray } from 'lodash';
import update from 'update-immutable';
import module from 'lib/module';
import { getSyncType } from 'lib/redux-utils';
import { getAvatarName } from 'lib/avatar';
import * as AuthTypes from 'modules/Auth/src/actionTypes';
import * as WalletTypes from 'modules/Wallet/src/actionTypes';
import { NAME, kyc, VerificationStatus } from './constants';
import { AT, T } from './actionTypes';
import { initialState } from './model';

const refresh = (state, action) => {
  const result = { ...action.payload };
  if (result.avatarImage) {
    result.avatar = result.avatarImage.startsWith('http')
      ? result.avatarImage
      : getAvatarName(result.avatarImage);
  }
  // TODO: Ask Iulian to normalize
  if (result.phoneNumberValidated) {
    result.phoneVerified = result.phoneNumberValidated;
    delete result.phoneNumberValidated;
  }
  return {
    ...state,
    ...result
  };
};

const toggleShowBalance = (state) =>
  update(state, {
    showBalance: { $apply: (val) => !val }
  });

const changeAvatar = (state, action) =>
  update(state, {
    avatar: { $set: action.meta && action.meta.data && action.meta.data.avatarImage }
  });

const changeEmail = (state, action) =>
  update(state, {
    email: { $set: action.meta && action.meta.data && action.meta.data.email }
  });

const changeAddress = (state, action) =>
  update(state, {
    $merge: action.meta && action.meta.data && action.meta.data
  });

const getVerification = (state, action) =>
  update(state, {
    $merge: {
      canAttemptVerification: action.payload.canAttemptVerification,
      canSendVerification: action.payload.canSendVerification,
      phoneNumber: action.payload.phoneNumber,
      phoneVerified: action.payload.verified
    }
  });

const resetVerification = (state) =>
  update(state, {
    $unset: ['canAttemptVerification', 'canSendVerification']
  });

const getReferralStatus = (state, action) =>
  update(state, {
    referees: { $set: action.payload.referees },
    freeSpinsEarned: { $set: action.payload.freeSpinsEarned },
    freeSpinsPending: { $set: action.payload.freeSpinsPending }
  });

const getFreeRounds = (state, { payload }) => {
  const isHardcoded = true;
  const defaultFreeRounds = 50;
  const defaultGameTitle = `Fishin' Frenzy The Big Catch`;
  return update(state, {
    freeRounds: { $set: isHardcoded ? defaultFreeRounds : payload.numberOfFreeRounds },
    freeRoundsGames: {
      $set: isHardcoded
        ? [{ name: defaultGameTitle }]
        : payload.slotGames.map((game) => ({
            ...game,
            title: game.title,
            src: game.logoImageUrl,
            alt: `${game.title} logo`
          }))
    }
  });
};

const getRewards = (state, { payload }) =>
  update(state, {
    rewards: { $set: payload }
  });

const setDailyFreeGames = (state, { payload }) =>
  update(state, {
    dailyFreeGames: { $set: payload }
  });

const netDeposits = (state, { payload }) =>
  update(state, {
    netDeposits: { $merge: payload }
  });

const netBets = (state, { payload }) =>
  update(state, {
    netBets: { $merge: payload }
  });

const getRewardsCount = (state, { payload }) =>
  update(state, {
    rewardsCount: { $set: payload }
  });

const getRewardOffers = (state, { payload }) =>
  update(state, {
    rewardOffers: { $set: payload }
  });

const getChatAvailability = (state, { payload }) =>
  update(state, {
    chatDisabled: { $set: payload.chatDisabled }
  });

const getFeedback = (state, { payload }) =>
  update(state, {
    feedback: { $set: payload.rating }
  });

const setRealityCheckInterval = (state, action) =>
  update(state, {
    realityCheckInterval: { $set: action.meta.data && action.meta.data.realityCheckInterval }
  });

const setSessionStats = (state, action) =>
  update(state, {
    sessionWinnings: { $set: action.payload.winnings },
    sessionLosses: { $set: action.payload.losses },
    sessionDurationInSeconds: { $set: action.payload.durationInSeconds }
  });

const setRealityTimeoutId = (state, action) =>
  update(state, {
    realityTimeoutId: { $set: action.payload }
  });

const setRealityIntervalId = (state, action) =>
  update(state, {
    realityIntervalId: { $set: action.payload }
  });

const gameSounds = (state, action) =>
  update(state, {
    gameSoundsEnabled: { $set: action.meta.data && action.meta.data.gameSoundsEnabled }
  });

const setOccupation = (state, action) =>
  update(state, {
    occupation: { $set: action.meta.data && action.meta.data.occupation }
  });

const finishDocScanSession = (state) =>
  update(state, {
    kycStatus: { $set: kyc.PENDING }
  });

const changeMarketingPreference = (state, action) => {
  const marketingMethodMap = {
    EMAIL: 'receiveEmail',
    SMS: 'receiveSms',
    CALLS: 'receiveCalls',
    POST: 'receivePost'
  };
  if (action.meta.data && action.meta.data.name) {
    const loading = state.loading;
    const type = getSyncType(action.type);
    const name = action.meta.data.name;
    const value = action.meta.data.value;
    const index = loading.indexOf(`${type}_${name}`);
    return update(state, {
      [marketingMethodMap[name]]: { $set: value },
      loading: { $splice: [[index, 1]] }
    });
  }
  return state;
};

const updateAvatarNotification = (state, action) =>
  update(state, {
    avatarNotification: { $set: action.payload }
  });

const clearReturningPlayer = (state) =>
  update(state, {
    returningPlayer: { $set: null }
  });

const setReturningPlayer = (state, action) =>
  update(state, {
    returningPlayer: { $set: action.payload }
  });

const setKyc = (state, { payload }) =>
  update(state, {
    kycStatus: { $set: payload.kycStatus },
    kycProviders: { $set: payload.availableProviders }
  });

const updateKycVerification = (state, { payload }) =>
  update(state, {
    verification: {
      $set: {
        type: payload?.journey,
        status: payload?.status,
        provider: payload?.provider
      }
    },
    requiresVerification: {
      $set: !!payload && payload.status !== VerificationStatus.APPROVED
    }
  });

const updateKycVerificationDocuments = (state, { payload }) =>
  update(state, {
    kycVerificationDocuments: { $set: payload }
  });

const updateAgreedToLatestTerms = (state) =>
  update(state, {
    agreedToLatestTerms: { $set: true }
  });

const setNotAgreedToLatestTerms = (state) =>
  update(state, {
    agreedToLatestTerms: { $set: false }
  });

const setLiveChatLauncherVisible = (state, action) =>
  update(state, {
    isLauncherVisible: { $set: action.payload }
  });

const setLiveChatUnreadCount = (state, action) =>
  update(state, { liveChatUnreadCount: { $set: action.payload } });

const loadingMarketingPreference = (state, { meta, type }) => {
  const loadingAction = `${getSyncType(type)}_${meta.data.name}`;
  return update(
    state,
    isArray(state.loading)
      ? {
          loading: { $push: [loadingAction] }
        }
      : update(state, {
          loading: { $set: [loadingAction] }
        })
  );
};

const rejectMarketingPreference = (state, action) => {
  if (action.meta.data && action.meta.data.name) {
    const loading = state.loading;
    const type = getSyncType(action.type);
    const name = action.meta.data.name;
    const index = loading.indexOf(`${type}_${name}`);

    return update(state, {
      loading: { $splice: [[index, 1]] }
    });
  }
  return state;
};

const updateHooyuLink = (state, action) => {
  if (action.payload.link) {
    return update(state, {
      hooyuLink: { $set: action.payload.link }
    });
  }
  return state;
};

const moduleReducer = module(
  handleActions(
    {
      [AT.GET_CURRENT.FULFILLED]: refresh,
      [AT.KYC.FULFILLED]: setKyc,
      [AT.FINISH_DOC_SCAN_SESSION.FULFILLED]: finishDocScanSession,
      [AT.CHANGE_AVATAR.FULFILLED]: changeAvatar,
      [AT.CHANGE_ADDRESS.FULFILLED]: changeAddress,
      [AT.CHANGE_EMAIL.FULFILLED]: changeEmail,
      [AT.SMS_VERIFICATION_STATE.PENDING]: resetVerification,
      [AT.SMS_VERIFICATION_STATE.FULFILLED]: getVerification,
      [AT.VERIFY_SMS.FULFILLED]: getVerification,
      [AT.CHANGE_MARKETING_PREFERENCE.PENDING]: loadingMarketingPreference,
      [AT.CHANGE_MARKETING_PREFERENCE.REJECTED]: rejectMarketingPreference,
      [AT.CHANGE_MARKETING_PREFERENCE.FULFILLED]: changeMarketingPreference,
      [AT.SET_REALITY_CHECK_INTERVAL.FULFILLED]: setRealityCheckInterval,
      [AT.SESSION_STATS.FULFILLED]: setSessionStats,
      [AT.GET_REFERRAL_STATUS.FULFILLED]: getReferralStatus,
      [AT.GET_SETTINGS.FULFILLED]: refresh,
      [AT.CHANGE_GAME_SOUNDS.FULFILLED]: gameSounds,
      [AT.GET_FREE_ROUNDS.FULFILLED]: getFreeRounds,
      [AT.GET_REWARDS.FULFILLED]: getRewards,
      [AT.GET_DAILY_FREE_GAMES.FULFILLED]: setDailyFreeGames,
      [AT.NET_DEPOSITS.FULFILLED]: netDeposits,
      [AT.NET_BETS.FULFILLED]: netBets,
      [AT.GET_REWARDS_COUNT.FULFILLED]: getRewardsCount,
      [AT.GET_REWARD_OFFERS.FULFILLED]: getRewardOffers,
      [AT.GET_CHAT_AVAILABILITY.FULFILLED]: getChatAvailability,
      [AT.GET_FEEDBACK.FULFILLED]: getFeedback,
      [AT.OCCUPATION.FULFILLED]: setOccupation,
      [AT.AGREE_LATEST_TERMS.FULFILLED]: updateAgreedToLatestTerms,
      [AT.CREATE_HOOYU_SESSION.FULFILLED]: updateHooyuLink,
      [AT.CREATE_HOOYU_REQUIREMENT_ID_SESSION.FULFILLED]: updateHooyuLink,
      [AT.KYC_VERIFICATION.FULFILLED]: updateKycVerification,
      [AT.KYC_VERIFICATION_DOCUMENTS.FULFILLED]: updateKycVerificationDocuments,
      [T.TOGGLE_SHOW_BALANCE]: toggleShowBalance,
      [T.SET_REALITY_TIMEOUT_ID]: setRealityTimeoutId,
      [T.SET_REALITY_INTERVAL_ID]: setRealityIntervalId,
      [T.UPDATE_AVATAR_NOTIFICATION]: updateAvatarNotification,
      [T.SET_RETURNING_PLAYER]: setReturningPlayer,
      [T.CLEAR_RETURNING_PLAYER]: clearReturningPlayer,
      [T.SET_NOT_AGREED_TO_LATEST_TERMS]: setNotAgreedToLatestTerms,
      [T.SET_LIVE_CHAT_UNREAD_COUNT]: setLiveChatUnreadCount,
      [T.SET_LIVE_CHAT_LAUNCHER_VISIBLE]: setLiveChatLauncherVisible
    },
    initialState
  ),
  NAME
);

const resetAuth = (state) =>
  update(state, {
    ref: {
      $set: null
    }
  });

const depositRejected = (state, { meta }) => {
  if (meta.code === '5570' && state.kycStatus === kyc.NONE) {
    return update(state, {
      kycStatus: {
        $set: kyc.REFER
      }
    });
  }

  if (meta.code === '5571') {
    return update(state, {
      kycStatus: {
        $set: kyc.NONE
      }
    });
  }

  return state;
};

const externalReducer = handleActions(
  {
    [AuthTypes.AT.LOGOUT.REJECTED]: resetAuth,
    [AuthTypes.AT.LOGOUT.FULFILLED]: resetAuth,
    [WalletTypes.AT.DEPOSIT_MOBILE.REJECTED]: depositRejected,
    [WalletTypes.AT.DEPOSIT_CARD_BRAINTREE.REJECTED]: depositRejected,
    [WalletTypes.AT.DEPOSIT_PAYPAL.REJECTED]: depositRejected
  },
  initialState
);

const reducer = (state = initialState, action) =>
  // FIXME: Correct check is `getNamespace(action.type) !== NAME` or `!action.type.startsWith(NAME)`
  isNil(action.type) || !action.type.includes(NAME)
    ? externalReducer(state, action)
    : moduleReducer(state, action);

export default reducer;
