import React, { memo } from 'react';
import type { ChangeEventHandler, ReactNode } from 'react';
import cc from 'classcat';
import reform from 'components/Reform';
import type { ReformChangeEvent, ReformField } from 'components/Reform/types';
import AmountPresetPicker from 'components/AmountPresetPicker';
import Fieldset from 'components/Fieldset';
import { MIN_DEPOSIT, MAX_DEPOSIT_CARD } from 'lib/valFunctors';
import { formatCurrency } from 'lib/formatters';
import FormError from 'components/FormError';
import Button from 'components/Button';
import type { SecureFieldElement } from 'components/PciProxy/types';
import { CvvField } from 'components/PciProxy';
import { Typography, TypographyVariant } from 'components/Typography';

const radioDefaultValues = [20, 50, 100, 150];

interface DepositPciProxyCardFormProps {
  isPromoCodeDisabled: boolean;
  isDepositWelcomeOfferVisible: boolean;
  disabled: boolean;
  radioValues: number[];
  depositAmount: ReformField;
  promotionCode: ReformField;
  minAmount: number;
  maxAmount: number;
  formValid: boolean;
  isPciProxyValid: boolean;
  errorMessage: string;
  pciProxyErrorMessage: string;
  loading: boolean;
  cvv: SecureFieldElement;
  handleChange: ReformChangeEvent;
  onSubmit: ChangeEventHandler<HTMLFormElement>;
  handleFocus: () => void;
  handlePciFocus: () => void;
  handleBlur: () => void;
  disableDepositButton?: boolean;
  fundsProtection?: ReactNode;
}

const DepositPciProxyCardForm = reform()(
  ({
    isPromoCodeDisabled,
    isDepositWelcomeOfferVisible,
    disabled,
    radioValues,
    depositAmount,
    minAmount,
    maxAmount,
    promotionCode,
    formValid,
    isPciProxyValid,
    errorMessage,
    pciProxyErrorMessage,
    loading,
    cvv,
    handleChange,
    fundsProtection,
    disableDepositButton,
    onSubmit,
    handleFocus,
    handlePciFocus,
    handleBlur
  }: DepositPciProxyCardFormProps) => {
    const handleAmountPresetChange = (value: number) => {
      handleChange({}, { name: 'depositAmount', value });
    };
    const isDepositButtonDisabled =
      disabled || !formValid || !depositAmount.valid || !isPciProxyValid || disableDepositButton;

    return (
      <form
        id="depositCardForm"
        name="depositPciProxyCard"
        onSubmit={onSubmit}
        className={cc(['form', disabled && 'form--disabled'])}
      >
        <CvvField cvv={cvv} handleFocus={handlePciFocus} />
        <div className={cc(['field field--form', disabled && 'noEdit'])}>
          <div className="field__content">
            <div className="fieldset fieldset--fullwidth depositPreset">
              <AmountPresetPicker
                values={radioValues || radioDefaultValues}
                onChange={handleAmountPresetChange}
                value={+depositAmount.value}
              />
            </div>
            <Fieldset
              // @ts-expect-error -- field is not assignable because Fieldset is not typed
              field={depositAmount}
              editable={!disabled}
              inputType="number"
              min={(minAmount || MIN_DEPOSIT).toString()}
              max={(maxAmount || MAX_DEPOSIT_CARD).toString()}
              step="1"
              name="depositAmount"
              className="fieldset--pound"
              labelText="Other"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
        </div>
        {!isPromoCodeDisabled && (
          <>
            <Fieldset
              // @ts-expect-error -- field is not assignable because Fieldset is not typed
              field={promotionCode}
              inputType="text"
              name="promotionCode"
              labelText="Promo code"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              editable={!disabled}
            />
            {isDepositWelcomeOfferVisible && promotionCode.value.length > 0 && (
              <Typography variant={TypographyVariant.BodySm}>
                <span className="promoCode__offer">
                  If this code is valid, it will override the offer above.
                </span>
              </Typography>
            )}
          </>
        )}
        {fundsProtection}
        <div className="fieldset fieldset--centerflex">
          <Button
            disabled={isDepositButtonDisabled}
            loading={loading}
            type="submit"
            buttonText={cc([
              'Deposit',
              depositAmount.value && formatCurrency(+depositAmount.value)
            ])}
          />
        </div>
        <FormError errorMessage={errorMessage || pciProxyErrorMessage} />
      </form>
    );
  }
);

export default memo(DepositPciProxyCardForm);
