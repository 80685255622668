import React, { memo } from 'react';
import cc from 'classcat';
import './swipeableCarouselPagination.css';

interface SwipeableCarouselPaginationProps {
  totalPages: number;
  currentPage: number;
  className?: string;
  onPageChange: (page: number, indexLatest: number) => void;
}

const SwipeableCarouselPagination = ({
  totalPages,
  currentPage,
  className,
  onPageChange
}: SwipeableCarouselPaginationProps) => (
  <div className={cc(['swipeableCarouselPagination', className])}>
    {Array.from({ length: totalPages }, (_, index) => (
      <div
        key={index}
        className={cc([
          'swipeableCarouselPagination__dot',
          currentPage === index && 'swipeableCarouselPagination__dot--active'
        ])}
        onClick={() => onPageChange(index, currentPage)}
      />
    ))}
  </div>
);

export default memo(SwipeableCarouselPagination);
