import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Api from 'services/Api';
import Auth from 'modules/Auth';
import Wallet from 'modules/Wallet';
import Modals from 'modules/Modals';
import { isLoading } from 'lib/redux-utils';
import FundsProtectionCheck from './components/FundsProtectionCheck';
import AddEcospendForm from './src/AddEcospendForm';

const AddEcospend = (props) => {
  const {
    fetchEcospendBanks,
    ecospendBanks,
    depositEcospendFirst,
    openDeposit,
    openTermsConditions,
    onCancelRequest,
    hasAgreedPaymentTerms,
    isPromoCodeDisabled,
    isDepositWelcomeOfferVisible,
    loading
  } = props;

  const [acceptFundsProtection, setAcceptFundsProtection] = useState(false);

  useEffect(() => {
    fetchEcospendBanks();
  }, [fetchEcospendBanks]);

  const handleFirstDeposit = async (depositData) => {
    const bankId = depositData.bank.bankId;
    const depositPayload = {
      agreeDepositTerms: acceptFundsProtection,
      bankId: bankId,
      amount: depositData.depositAmount,
      promotionCode: depositData.promotionCode,
      bankName: depositData.bank?.friendlyName
    };
    depositEcospendFirst(depositPayload);
  };

  const fundsProtectionCheck = !hasAgreedPaymentTerms && (
    <FundsProtectionCheck
      openTermsConditions={openTermsConditions}
      onChange={() => setAcceptFundsProtection((s) => !s)}
      checked={acceptFundsProtection}
    />
  );

  return (
    <AddEcospendForm
      ecospendBanks={ecospendBanks}
      onAnotherMethod={openDeposit}
      submit={handleFirstDeposit}
      openTermsConditions={openTermsConditions}
      loading={loading}
      onCancelRequest={onCancelRequest}
      fundsProtectionCheck={fundsProtectionCheck}
      isPromoCodeDisabled={isPromoCodeDisabled}
      isDepositWelcomeOfferVisible={isDepositWelcomeOfferVisible}
      disableDepositButton={!(hasAgreedPaymentTerms || acceptFundsProtection)}
    />
  );
};

AddEcospend.propTypes = {
  openDeposit: PropTypes.func.isRequired,
  depositEcospendFirst: PropTypes.func.isRequired,
  fetchEcospendBanks: PropTypes.func.isRequired,
  openTermsConditions: PropTypes.func.isRequired,
  ecospendBanks: PropTypes.object,
  isPromoCodeDisabled: PropTypes.bool,
  isDepositWelcomeOfferVisible: PropTypes.bool,
  loading: PropTypes.bool
};

AddEcospend.defaultProps = {
  ecospendBanks: {},
  isPromoCodeDisabled: false,
  isDepositWelcomeOfferVisible: false,
  loading: false
};

const mapStateToProps = (state) => ({
  hasAgreedPaymentTerms: Wallet.selectors.hasAgreedPaymentTerms(state),
  canAlterPrimaryMethod: Wallet.selectors.canAlterPrimaryMethod(state),
  ecospendBanks: Wallet.selectors.getEcospendBanks(state),
  loading: isLoading(state, [Auth.AT.FINGERPRINT._, Wallet.actionTypes.AT.DEPOSIT_ECOSPEND_FIRST._])
});

const mapDispatchToProps = (dispatch, { onDone }) => ({
  fetchEcospendBanks: () => Api.actions.wallet.ecospendBanks()(dispatch),
  onCancelRequest: () => onDone(),
  openDeposit: () => {
    onDone();
    dispatch(Modals.actions.open('deposit'));
  },
  openTermsConditions: () => dispatch(Modals.actions.open('termsConditions')),
  depositEcospendFirst: async (payload) => {
    const { value: deviceInfo } = await dispatch(Auth.actions.getDeviceFingerprint());
    try {
      const res = await Api.actions.wallet.depositEcospendFirst(null, { ...payload, deviceInfo })(
        dispatch
      );
      if (res.success) {
        const modalPayload = {
          bankName: payload.bankName,
          bankLogo: res.data.bankLogo
        };
        onDone();
        dispatch(Modals.actions.open('depositEcospendFlow', modalPayload));
      }
    } catch (e) {
      // doNothing, a notification will be shown, controlled by the backend.
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEcospend);
