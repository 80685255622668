import type { CmsContentState } from './types';

export const initialState: CmsContentState = {
  appFooter: null,
  appFooterLeftColumn: null,
  appFooterRightColumn: null,
  bingoRules: null,
  theme: null,
  occupations: []
};
