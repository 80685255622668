import React, { memo, useCallback, useState } from 'react';
import { mod } from 'react-swipeable-views-core';
import { virtualize } from 'react-swipeable-views-utils';
import SwipeableViews from 'react-swipeable-views';
import cc from 'classcat';
import { ArrowButton } from 'components/RowList';
import withLazyStyle from 'components/LazyStyle';
import SwipeableCarouselPagination from './components/SwipeableCarouselPagination';
import lazyStyle from './swipeableCarousel.css?lazy';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

type VirtualizeSwipeableViewsProps = React.ComponentPropsWithoutRef<
  typeof VirtualizeSwipeableViews
>;

interface SwipeableCarouselProps extends VirtualizeSwipeableViewsProps {
  totalItemsCount?: number;
  className?: string;
}

const SwipeableCarousel = ({
  totalItemsCount,
  onChangeIndex,
  className,
  index: currentIndex,
  ...props
}: SwipeableCarouselProps) => {
  const [slideIndex, setSlideIndex] = useState(currentIndex ?? 0);
  /**
   * Updates the slide index, ensuring changes are smooth and step by step.
   * If the target index skips more than one slide, it adjusts to move stepwise by 1.
   */
  const handleChangeIndex = useCallback(
    (index: number, indexLatest: number) => {
      let newIndex = index;
      // Prevent skipping more than one slide at a time
      if (Math.abs(index - indexLatest) > 1) {
        // Adjust the new index to step by 1 in the correct direction
        newIndex = index > indexLatest ? indexLatest + 1 : indexLatest - 1;
      }
      onChangeIndex?.(newIndex, indexLatest);
      setSlideIndex(newIndex);
    },
    [onChangeIndex]
  );

  return (
    <>
      <div className={cc(['swipeableCarousel', className])}>
        <VirtualizeSwipeableViews {...props} index={slideIndex} onChangeIndex={handleChangeIndex} />
        <ArrowButton
          direction="left"
          className="swipeableCarousel--arrowButton swipeableCarousel--arrowButton--left"
          handleClick={() => handleChangeIndex(slideIndex - 1, slideIndex)}
        />
        <ArrowButton
          direction="right"
          className="swipeableCarousel--arrowButton swipeableCarousel--arrowButton--right"
          handleClick={() => handleChangeIndex(slideIndex + 1, slideIndex)}
        />
      </div>

      {totalItemsCount && (
        <SwipeableCarouselPagination
          className="swipeableCarousel--swipeableCarouselPagination"
          currentPage={mod(slideIndex, totalItemsCount)}
          totalPages={totalItemsCount}
          onPageChange={handleChangeIndex}
        />
      )}
    </>
  );
};

export default memo(withLazyStyle(lazyStyle)(SwipeableCarousel));
