import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import { formatCurrency } from 'lib/formatters';
import { constants as UserConstants } from 'modules/User';
import { AttentionCircleIcon } from 'components/Icons';
import './userMenuContent.css';

interface UserMenuProps {
  fullName: string;
  openDeposit: () => void;
  openReferAfriend: () => void;
  openWithdraw: () => void;
  handleVerifyAccountClick: () => void;
  logout: () => void;
  globalJackpotAmount: number;
  requiresVerification: boolean;
  verificationStatus: UserConstants.VerificationStatus | null;
  freeRounds: number;
}
const UserMenu = ({
  fullName = '...',
  openDeposit,
  openReferAfriend,
  openWithdraw,
  handleVerifyAccountClick,
  logout,
  requiresVerification,
  verificationStatus,
  globalJackpotAmount = 0,
  freeRounds = 0
}: UserMenuProps) => (
  <div className="menu menu--user">
    <span className="menu__section menu__fullName">{fullName}</span>
    <div className="menu__section menu__deposit">
      <Button
        buttonText="Deposit"
        onClick={openDeposit}
        name="openDeposit"
        id="userMenu-openDeposit"
        className="button--fullWidth button__grey menu__deposit-button"
      />
      <Button
        buttonText="Withdraw"
        onClick={openWithdraw}
        name="openWithdrawal"
        id="userMenu-openWithdrawal"
        className="button--fullWidth button__grey menu__deposit-button"
      />
    </div>
    {globalJackpotAmount > 0 ? (
      <div className="menu__section menu__progressive">
        <div className="menu__progressive-inner">
          <img
            className="menu__progressive-logo"
            src="/assets/images1/mrq-jackpot.svg"
            alt="MrQ Progressive Jackpot"
          />
          <div className="menu__progressive-section">
            <span className="menu__progressive-amount">
              {formatCurrency(globalJackpotAmount, { pennies: false })}
            </span>
            <a href="#" className="menu__progressive-help">
              What&apos;s this?
            </a>
          </div>
        </div>
      </div>
    ) : null}
    <ul className="menu__section menu__navigation">
      <li>
        <Link to="/secure/user" title="Account overview">
          My account
        </Link>
      </li>
      {requiresVerification &&
        verificationStatus === UserConstants.VerificationStatus.REQUESTED && (
          <li>
            <a onClick={handleVerifyAccountClick} title="Verify account">
              <AttentionCircleIcon color="#D50000" />
              <span>Verify account</span>
            </a>
          </li>
        )}
      {freeRounds ? (
        <li>
          <a onClick={openReferAfriend} title="Bonus!" className="menu__referLink">
            Get {freeRounds} Free Spins!
          </a>
        </li>
      ) : null}
    </ul>
    <button
      type="button"
      className="button button__white"
      onClick={logout}
      data-test-id="user-logout"
    >
      Log out
    </button>
  </div>
);

export default memo(UserMenu);
