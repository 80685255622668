// Usage information: https://github.com/lindar-joy/aws-sih#usage
// Edits options:  https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/create-and-use-image-requests.html
// All sharp options are supported under edits: https://sharp.pixelplumbing.com/api-output#jpeg

export const ImageFormatTypes = {
  jpg: 'jpg',
  jpeg: 'jpeg',
  png: 'png',
  webp: 'webp',
  tiff: 'tiff',
  heif: 'heif',
  heic: 'heic',
  raw: 'raw',
  gif: 'gif'
};

export interface CDNImage {
  width?: number;
  height?: number;
  ignoreGif?: boolean;
  format?: keyof typeof ImageFormatTypes;
  /** `number`: Multiply width/height (see [cloudflare docs](https://developers.cloudflare.com/images/image-resizing/url-format/#dpr))
   *
   * `"auto"`: Automatically add dpr from `window.devicePixelRatio` */
  dpr?: (typeof allowedDprs)[number] | 'auto';
  quality?: number;
  blur?: number; // 0 - 250
  brightness?: number; // 0 - 2 (1 means no change)
  // We can add support for other options as well, but for now these are enough
}
const DEFAULT_QUALITY = 92;
const DEFAULT_FORMAT = 'webp';
const IMAGE_CDN_ROOT = __ENV__.MRQ_URL_IMAGE_CDN;

// Device pixel ratio
const allowedDprs = [1, 2, 3] as const;
let dpr = 1;

// Dpr is often fractional, we don't want an infinite number of variations
// Works like the native srcset="xx.jpg 2x". Prefer srcSet and add the dpr option instead
// when used in img tags
export const getClosestDpr = (deviceDpr: number) => {
  const roundDpr = Math.round(deviceDpr);
  return allowedDprs.reduce((prev, curr) =>
    Math.abs(curr - roundDpr) < Math.abs(prev - roundDpr) ? curr : prev
  );
};

// Update dpr when changing screen resolution or moving the window to another screen.
// We don't want to trigger a Component update, instead when the Component is updated from other
// causes, it will get the latest url with the updated dpr.
const updatePixelRatio = () => {
  const deviceDpr = window.devicePixelRatio;
  dpr = getClosestDpr(deviceDpr);
  const resolutionQuery = matchMedia(`(resolution: ${dpr}dppx)`);
  if (resolutionQuery.addEventListener) {
    resolutionQuery.addEventListener('change', updatePixelRatio, {
      once: true
    });
  } else {
    resolutionQuery.removeListener(updatePixelRatio);
    resolutionQuery.addListener(updatePixelRatio);
  }
};

updatePixelRatio();

const removeAWSHost = (input: string) => {
  const regex = /https:\/\/.*?amazonaws\.com/g;
  return input.replace(regex, '');
};

const acceptedFormats = [
  ImageFormatTypes.jpeg,
  ImageFormatTypes.png,
  ImageFormatTypes.webp,
  ImageFormatTypes.tiff,
  ImageFormatTypes.heif,
  ImageFormatTypes.gif
];

/**
 * #### Creates new URL with AWS's Serverless Image Handler CDN prefix.
 * ---
 * ***Default options:***
 * ```
 * - quality: 92
 * - format: 'jpeg'
 * - ignoreGif: false
 * ```
 * @remarks The URL returned by this function will always reflect the current DPR of the device.
 * @param {string}  url - URL of the image.
 * @param {string} options - configure size, format, dimensions and quality
 * @returns {string} return new URL with the cloudflare prefix. If ignoreGif is enabled and the url ends with ".gif",
 * the original URL is returned.
 */

const cdnImage = (url: string, options?: CDNImage) => {
  if (!url) {
    return;
  }
  if (
    !IMAGE_CDN_ROOT ||
    (options?.format && options?.ignoreGif && !acceptedFormats.includes(options?.format))
  ) {
    return url;
  }
  if (options?.ignoreGif && url.endsWith('.gif')) {
    // Cloudflare doesn't support resizing of gif images,
    // therefore we can ignore the resizing if we need to keep the gif image.
    // For context, sometime featured slot games get animated thumbnails.
    return url;
  }

  const format = options?.format || DEFAULT_FORMAT;
  const quality = { quality: options?.quality || DEFAULT_QUALITY };
  const width = options?.width && { width: dpr * options.width };
  const height = options?.height && { height: dpr * options.height };
  const resize = (width || height) && {
    resize: { ...width, ...height, fit: 'cover', withoutEnlargement: true }
  };
  const blur = options?.blur && { blur: options?.blur };
  const brightness = options?.brightness && { brightness: options?.brightness };
  const modulate = brightness && { modulate: brightness };

  const edits = {
    ...resize,
    ...blur,
    ...modulate,
    [format]: quality
  };

  const params = new URLSearchParams();
  params.set('outputFormat', format);
  params.set('edits', JSON.stringify(edits));

  return `${IMAGE_CDN_ROOT}${removeAWSHost(url)}?${params.toString()}`;
};

export default cdnImage;
