import type { Offer } from 'lib/LobbyEntities';

export const SpinDayStatus = {
  PAST: 'PAST',
  CURRENT: 'CURRENT',
  FUTURE: 'FUTURE'
} as const;
export type SpinDayStatus = (typeof SpinDayStatus)[keyof typeof SpinDayStatus];

export type SpinDay = {
  ref: string;
  dateText: string;
  isBlocked: boolean;
  status: SpinDayStatus;
  thumbnailUrl: string;
  expiresText: string;
  details: { game: string; numberOfFreespins: number };
};

export type RewardsCarousel = {
  ref: string;
  title: string;
  termsAndConditions: string;
  subOffers: SpinDay[];
};

export interface OffersState {
  registration: null | Offer[];
  referAFriend: null | Offer[];
  rewardsCarousel: null | RewardsCarousel[];
}
