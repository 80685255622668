import React, { Component } from 'react';
import { connect } from 'react-redux';
import cc from 'classcat';
import { compose } from 'redux';
import { isMobile } from 'react-device-detect';
import Api from 'services/Api';
import reform from 'components/Reform';
import Fieldset from 'components/Fieldset';
import Auth from 'modules/Auth';
import Modals from 'modules/Modals';
import Wallet from 'modules/Wallet';
import FormError from 'components/FormError';
import Button from 'components/Button';
import AmountPresetPicker from 'components/AmountPresetPicker';
import { isLoading } from 'lib/redux-utils';
import { formatCurrency } from 'lib/formatters';
import { promotionCode as promotionCodeValidation } from 'lib/valFuncs';
import { Typography, TypographyVariant } from 'components/Typography';
import {
  MIN_DEPOSIT,
  MAX_DEPOSIT_CARD,
  depositAmount as depositAmountValidation
} from 'lib/valFunctors';

const radioDefaultValues = [20, 50, 100, 150];
const enhance = compose(reform());

class BaseForm extends Component {
  handleAmountPresetChange = (value) => {
    const { handleChange } = this.props;
    handleChange({}, { name: 'depositAmount', value });
  };

  render() {
    const {
      isPromoCodeDisabled,
      fundsProtection,
      disableDepositButton,
      depositAmount,
      promotionCode,
      radioValues,
      loading,
      disabled,
      formValid,
      handleChange,
      handleFocus,
      handleBlur,
      minAmount,
      maxAmount,
      isDisabled,
      bankName,
      providerUnavailableDescription,
      isDepositWelcomeOfferVisible,
      errorMessage,
      onSubmit
    } = this.props;
    const buttonClass = cc([{ 'button--loading': loading }]);
    const isDepositButtonDisabled =
      disabled || !formValid || !depositAmount.valid || isDisabled || disableDepositButton;

    return (
      <form
        id="depositCardForm"
        name="depositEcospend"
        onSubmit={onSubmit}
        className={disabled ? 'form form--disabled' : 'form'}
      >
        <div className={cc(['field field--form', disabled && 'noEdit'])}>
          <span className="field__title">Deposit amount</span>
          <div className="field__content">
            <div className="fieldset fieldset--fullwidth depositPreset">
              <AmountPresetPicker
                values={radioValues || radioDefaultValues}
                onChange={this.handleAmountPresetChange}
                value={+depositAmount.value}
              />
            </div>
            <Fieldset
              field={depositAmount}
              editable={!disabled}
              inputType="number"
              min={(minAmount || MIN_DEPOSIT).toString()}
              max={(maxAmount || MAX_DEPOSIT_CARD).toString()}
              step="1"
              name="depositAmount"
              className="fieldset--pound"
              labelText="Other"
              onChange={handleChange}
              onFocus={handleFocus}
            />
          </div>
        </div>
        {!isPromoCodeDisabled && (
          <>
            <Fieldset
              field={promotionCode}
              inputType="text"
              name="promotionCode"
              labelText="Promo code"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              editable={!disabled}
            />
            {isDepositWelcomeOfferVisible && promotionCode.value.length > 0 && (
              <Typography variant={TypographyVariant.BodySm}>
                <span className="promoCode__offer">
                  If this code is valid, it will override the offer above.
                </span>
              </Typography>
            )}
          </>
        )}
        {fundsProtection}
        <div className="fieldset fieldset--centerflex">
          <Button
            className={buttonClass}
            disabled={isDepositButtonDisabled}
            loading={loading}
            type="submit"
            buttonText={cc(['Deposit', depositAmount.value && formatCurrency(depositAmount.value)])}
          />
        </div>
        <FormError
          errorMessage={
            errorMessage || (isDisabled ? `${bankName} ${providerUnavailableDescription}` : '')
          }
        />
      </form>
    );
  }
}

export const Form = enhance(BaseForm);

export const _DepositEcospendFormNew = (props) => {
  const fields = {
    depositAmount: {
      initial: props.defaultAmount || 50,
      required: true,
      error: `Minimum amount ${formatCurrency(10)}`,
      onChange: depositAmountValidation(props.minAmount, props.maxAmount)
    },
    promotionCode: {
      initial: props.promoCode || '',
      required: false,
      onChange: promotionCodeValidation,
      error: "This doesn't look like a valid code"
    }
  };

  return <Form fields={fields} {...props} />;
};

const mapStateToProps = (state, { provider }) => ({
  defaultAmount: provider && Wallet.selectors.getPaymentPresetPresets(state, provider)?.[0],
  minAmount: provider && Wallet.selectors.getPaymentPresetMinAmount(state, provider),
  maxAmount: provider && Wallet.selectors.getPaymentPresetMaxAmount(state, provider),
  radioValues: provider && Wallet.selectors.getPaymentPresetPresets(state, provider),
  providerUnavailableDescription:
    provider && Wallet.selectors.getPaymentProviderUnavailableDescription(state, provider),
  loading: isLoading(state, [Auth.AT.FINGERPRINT._, Wallet.actionTypes.AT.DEPOSIT_ECOSPEND._])
});

const mapDispatchToProps = (
  dispatch,
  { paymentMethodRef, bankLogo, identifier, bankName, onDone, agreeDepositTerms }
) => ({
  submit: async ({ depositAmount, promotionCode }) => {
    const { value: deviceInfo } = await dispatch(Auth.actions.getDeviceFingerprint());
    const depositPayload = {
      agreeDepositTerms: agreeDepositTerms,
      amount: depositAmount,
      promotionCode: promotionCode,
      paymentMethodRef: paymentMethodRef,
      deviceInfo: deviceInfo
    };
    const res = await Api.actions.wallet.depositEcospend(null, depositPayload)(dispatch);
    if (res.success) {
      if (isMobile && identifier && res?.data?.paymentUrl) {
        window.location.replace(res.data.paymentUrl);
      } else {
        const modalPayload = {
          bankLogo: bankLogo,
          bankName: bankName,
          initialStep: Wallet.constants.ECOSPEND_STEP.FIRST_DEPOSIT_CONFIRMATION
        };
        dispatch(Modals.actions.open('depositEcospendFlow', modalPayload));
        onDone();
      }
    }
  },
  onCancelRequest: () => {
    dispatch(Modals.actions.close('deposit'));
  },
  openDepositLimit: () => {
    dispatch(Modals.actions.close('deposit'));
    dispatch(Modals.actions.open('depositLimit', { onCloseOpen: 'deposit' }));
  },
  openTermsAndConditions: () => {
    dispatch(Modals.actions.close('deposit'));
    dispatch(Modals.actions.open('termsConditions', { onCloseOpen: 'deposit' }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(_DepositEcospendFormNew);
