import React, { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { EffectTypes } from 'services/Effects';
import Analytics from 'modules/Analytics';
import makeModal from 'components/Modals';
import UserBox from 'components/UserBox';
import 'components/RewardOfferCard/offerCard.css';
import Carousel from 'modules/Carousel';
import RewardOfferInfo from './RewardOfferInfo';

interface RewardOfferData {
  ref: string;
  type: string;
  title?: string;
  content?: string;
  heading?: string;
  termsAndConditions?: string;
  cta?: string;
  ctaText?: string;
  imageUrl?: string;
}

interface RewardOfferDetailsProps {
  close: () => void;
  rewardOfferData: RewardOfferData;
  source: EffectTypes.OfferClickedSourceType;
}

const RewardOfferDetails = ({ close, rewardOfferData, source }: RewardOfferDetailsProps) => {
  const { ref, type, title, content, heading, termsAndConditions, cta, ctaText, imageUrl } =
    rewardOfferData || {};
  const activeTopRef = useAppSelector((state) =>
    // @ts-ignore -- no TS support for selectors yet
    Carousel.selectors.getActiveTopRef(state, 'lobbyMiddle')
  );
  const dispatch = useAppDispatch();
  const showCTAButton = Boolean(activeTopRef === 'rewards' ? cta : cta || ctaText);

  const handleClaimOfferClick = useCallback(() => {
    dispatch(
      Analytics.actions.offerClicked({
        refName: ref,
        type: type || 'SIMPLE',
        source,
        interactionType: EffectTypes.OfferClickedInteractionType.ModalCTAClick
      })
    );
    close();
  }, [close, dispatch, ref, type, source]);

  // if the user directly triggers the modal by accessing the page with the "?modals=rewardOffer"
  // in the query parameters for whatever reason, this use effect will take care of that
  // and, it will close the modal since the "rewardOfferData" will be undefined
  useEffect(() => {
    if (!rewardOfferData) close();
  }, [rewardOfferData, close]);

  return (
    // Invalid offer shouldn't happen, but I'm gonna leave it just in case because the UserBox doesn't
    // render the close button if the title is undefined. Should this ever happen, the user will be able
    // to find and click the close button.
    <UserBox id="offerCardModal" title={title || 'Invalid offer'} closeCallback={close}>
      <RewardOfferInfo
        title={title}
        termsAndConditions={termsAndConditions}
        content={content}
        heading={heading}
        imageUrl={imageUrl}
        showCTAButton={showCTAButton}
        cta={cta}
        ctaText={ctaText}
        onClaimOfferClick={handleClaimOfferClick}
      />
    </UserBox>
  );
};

export default makeModal(
  'rewardOffer',
  { name: 'modal-fade', timeout: 200 },
  { className: 'modal rewardOffer' }
)(RewardOfferDetails);
