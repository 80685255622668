import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import CmsContent from 'modules/CmsContent';
import usePublicCmsData from 'hooks/usePublicCmsData';
import Logo from 'components/Logo';

interface ThemeLogoProps {
  inverted?: boolean;
}

const fallbackDefaultLogo = {
  url: '/assets/images1/mrq-logo.svg',
  mime: 'image/svg+xml'
};
const fallbackInvertedLogo = {
  url: '/assets/images1/mrq-logo-white.svg',
  mime: 'image/svg+xml'
};

const ThemeLogo = ({ inverted }: ThemeLogoProps) => {
  const dispatch = useAppDispatch();
  const updateTheme = useCallback(
    (cmsResponse) => {
      const data = cmsResponse?.data[0];
      if (data) {
        dispatch(CmsContent.actions.cacheContent('theme', data));
      }
    },
    [dispatch]
  );
  const theme = useAppSelector(CmsContent.selectors.getTheme);

  const { hasError } = usePublicCmsData({
    contentPath: '/api/themes',
    hasContent: Boolean(theme),
    updateContent: updateTheme
  });

  const fallbackLogo = inverted ? fallbackInvertedLogo : fallbackDefaultLogo;
  const themeLogo = inverted ? theme?.logoInverted : theme?.logo;
  const logo = hasError ? fallbackLogo : themeLogo;
  if (!logo) {
    return null;
  }

  return <Logo src={logo.url} autoRefresh={logo.mime.includes('gif')} />;
};

export default ThemeLogo;
