import Cookies from 'js-cookie';

const returningPlayerCookie = 'MRQ_LAST_LOGIN';

interface ReturningPlayerDetails {
  avatar: string;
  email: string;
  firstName: string;
}

export const getReturningPlayer = (): ReturningPlayerDetails | null => {
  const returningPlayer = Cookies.get(returningPlayerCookie);
  if (!returningPlayer) {
    return null;
  }

  return JSON.parse(atob(returningPlayer)) as ReturningPlayerDetails;
};

export const clearReturningPlayerCookie = () => {
  Cookies.remove(returningPlayerCookie, {
    domain: window.location.hostname
  });
};
